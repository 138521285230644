* {
    box-sizing: border-box;
    font: inherit;
    background-color: black;
    color: white;
  }
  
  body {
    font-family: sans-serif;
    margin: 20px;
    padding: 0;
  }
  
  button:hover,
  button:focus {
    background-color:red;
  }

  button {
    background-color: #a6001e;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  
  
  input {
    background-color: lightgray;
    
    padding: 10px 16px;
    font-size: 14px;
    line-height: 20px;
    color: #24292e;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: right 8px center;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    outline: none;
    box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
    :focus{
        border-color: #0366d6;
        outline: none;
        box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
    
  }

  h1 {
    font-size: 22px;
  }
  
  h2 {
    font-size: 20px;
  }
  
  h3 {
    font-size: 18px;
  }
  
  h4 {
    margin-top: 0;
    font-size: 16px;
  }
  
  h5 {
    margin-top: 0;
    font-size: 14px;
  }
  
  h6 {
    margin-top: 0;
    font-size: 12px;
  }
  
  code {
    font-size: 1.2em;
  }
  
  ul {
    padding-inline-start: 20px;
  }
  
  .square {
    background: #fff;
    border: 1px solid #999;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 40px;
    display: inline-block;
    margin: 2px 5px;
    background-color: #eee;
    border: 2px solid #ccc;  
    border-radius: 50%;
  }

  .board-row {
    text-align: center;
    align-content: center;
    align-items: center;
    width: 45%;
  }
  
  .board-row:after {
    clear: both;
    content: '';
  }
  
  .status {
    margin-bottom: 10px;
  }
  .game {
    display: flex;
    flex-direction: row;
  }
  
  .game-info {
    margin-left: 20px;
  }
  
  .column {
    float: left;
    width: 50%;
  }

  button{
    position: relative;
  }

  input{
    position: relative;
  }

  .GameJoin,
  .GameCreate {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gamecode {
    float: left;
    margin-top: 5%;
    padding-top: 10px;
  }
  
  .leavebutton {
    float: right;
    margin-left: 3rem;
    margin-top: 5%;
  }

  b {
    font-weight: bolder;
  }

  .ct-logo {
    width: 20%;
    left:right;
    margin-left: 15%;
    padding-top: 2%;
    padding-bottom: 3%;
  }

  .partner-logo {
    width: 20%;
    float:right;
    margin-right:25%;
    margin-top: 2%;
  }

  .game-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }